import React, { useEffect } from "react";
import "@css/customer.css";
import bosal from "@images/company/customer-img/1.png";
import easun from "@images/company/customer-img/2.png";
import fft from "@images/company/customer-img/3.png";
import gaobologo from "@images/company/customer-img/4.png";
import gpluse from "@images/company/customer-img/5.png";
import grammer from "@images/company/customer-img/6.png";
import intron from "@images/company/customer-img/7.png";
import kostal from "@images/company/customer-img/8.png";
import mold from "@images/company/customer-img/9.png";
import penn from "@images/company/customer-img/10.png";
import sdh from "@images/company/customer-img/11.png";
import sitech from "@images/company/customer-img/12.png";
import ute from "@images/company/customer-img/13.png";
import yfpo from "@images/company/customer-img/14.png";
import zuiko from "@images/company/customer-img/15.png";
import shobo from '@images/company/customer-img/16.png';
import power from '@images/company/customer-img/17.png';
import nswi from '@images/company/customer-img/18.png'

import APD from "@images/company/customer-img/19.png";
import BBS from "@images/company/customer-img/20.png";
import Kostal from "@images/company/customer-img/21.png";
import newasia from "@images/company/customer-img/22.png"; 
import zhiyanhui from '@images/company/customer-img/23.png';
import sodecia from '@images/company/customer-img/24.png';
import wuyang from '@images/company/customer-img/25.png'
const Customer = () => {
  const customImg = [
    bosal, easun, fft, gaobologo, gpluse, grammer, intron, kostal, mold,
    penn, sdh, sitech, ute, yfpo, zuiko, shobo, power, nswi, APD, BBS, Kostal,
    newasia, zhiyanhui, sodecia, wuyang
  ];
  return (
    <div className="client">
      <div className="client_title">
        <h1>客户</h1>
      </div>
      <div className="image-row"> 
        {customImg.map((image, index) => (
          <div
            style={{
              margin: '10px'
            }}
            key={index}
          >
            <img className={index === 11 ? 'image-png' : "image-style"} src={image} alt="" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Customer;
